import React, { Component } from "react";
import { Image as Images } from "../../../Images";
import { Select } from "antd";
import { formatDate } from "../../../../Controller/utils";
import { CaretDownOutlined } from "@ant-design/icons";
import { handleError } from "../../../../Controller/Global";
import { getProposalPdf } from "../../../../Controller/api/proposalServices";
import { connect } from "react-redux";
import { getGoogleAuthenticateURL } from "../../../../Controller/api/authServices";
import CommonWarningModal from "../../../modals/CommonWarningModal";

const { Option } = Select;

class ProposalTemplateGeneralInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileURL: null,
            pdfValue: 'select',
            pdfLoading: false,
            googleModalVisible: false
        }
    }


    handleGoogleBtnClick = () => {
        getGoogleAuthenticateURL({ redirect_url: window.location.href }).then(
            (r) => {
                window.location.href = r.data.url;
            }
        );
    };

    handleChange = (value) => {
        const user = this.props.userdata;
        if (value === "send_pdf") {
            if(user?.google_authorised_email) {
                this.props.callbackSendProposal();
            }
            else {
                this.setState({googleModalVisible: true})
            }
        }
        else {
            this.props.onTabChange('5');
        }
        this.setState({pdfValue: 'select'})
    };

    render() {
        const { proposal, statusTypes, user, proposal_filled } = this.props;
        const {googleModalVisible} = this.state;
        return (
            <div className="row summary-collapse-inner-row-main">
                <div className="col-12">
                    <div className="row summary-view-row-vehicle" style={{ border: 0}}>
                        <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                            <h6 className="text-uppercase">Template Id</h6>
                            <h5 className="mb-0">{proposal.id}</h5>
                        </div>
                        <div className="col-12 col-sm-6 col-md-3 col-lg-3 mb-15-bt">
                            <h6 className="text-uppercase">Proposal Template Name</h6>
                            <h5 className="mb-0" style={{fontWeight: 700}}>{proposal.name}</h5>
                        </div>
                    </div>
                </div>
                <CommonWarningModal
                    heading={
                        "You need to sign in with Google to move forward. Would you like to sign in?"
                    }
                    subHeadingUOM={`If you would like to sign in with Google, you would be redirected to Google's sign in page. After signing in, you would be brought back to the dashboard.`}
                    handleGoogleBtnClick={() => {
                        this.handleGoogleBtnClick();
                    }}
                    visible={googleModalVisible}
                    onClose={() => {
                        this.setState({googleModalVisible: false});
                    }}
                    googleSigninModal
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps, null)(ProposalTemplateGeneralInfo);
