import React, { Component } from 'react';
import {Button, DatePicker, Dropdown, Form, Input, Menu, message, Select, TimePicker} from "antd";
import { createWorkOrder, updateWorkOrder } from '../../../../Controller/api/workOrderServices';
import { handleError } from '../../../../Controller/Global';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import {Image as Images} from "../../../Images";
import {userTypes} from "../../../../Controller/userTypes";

const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
// const moment = require('moment');
class WorkOrderServiceInfoCreate extends Component {

    state = {
        btnLoader: false,
        selectedContact: null
    }
    formRef = React.createRef();

    handleSubmit = (val) => {
        const params = {
            service_date: val.service_date.format("YYYY-MM-DD"),
            start_time: val.start_time.format("HH:mm:ss.sss"),
            end_time:val.end_time ? val.end_time.format("HH:mm:ss.sss") : null,
            billing_account_po:val.billing_account_po || null,
            site_arrival_time: val.site_arrival_time ? val.site_arrival_time.format("HH:mm:ss.sss") : null,
            shop_arrival_time: val.shop_arrival_time ? val.shop_arrival_time.format("HH:mm:ss.sss") : null,
            site_contact: this.state.selectedContact ? this.state.selectedContact.id : null
        }
        this.setState({ btnLoader: true });
        if (this.props.workOrder) {
            updateWorkOrder(this.props.workOrder.id, params).then(res => {
                this.props.setWorkOrder(res.data, 6);
                message.success('Service information updated successfully!')
            }).catch(err => {
                handleError(err)
            }).finally(() => {
                this.setState({ btnLoader: false })
            })
        } else {
            createWorkOrder(params).then(res => {
                this.props.setWorkOrder(res.data, 6);
                message.success('Service information created successfully!')
            }).catch(err => {
                handleError(err)
            }).finally(() => {
                this.setState({ btnLoader: false })
            })
        }
    }

    removeContact = (val) => {
        this.setState({ ...this.state, selectedContact: null })
    }
    handleSiteContactChange = (val, contactDetails) => {
        this.formRef.current.setFieldsValue({
            contactSelect: null,
        });
        this.setState({ selectedContact: contactDetails.contact })
    }

    filterSiteContact = (input, contacts) => {
        const contact_name = contacts.contact?.full_name;
        const contact_number = contacts.contact?.phone_numbers[0]?.phone_number;
        const contact_email = contacts.contact?.emails[0]?.email;

        if (contact_name) return contact_name.toLowerCase().includes(input.toLowerCase());
        if (contact_number) return contact_number.toLowerCase().includes(input.toLowerCase());
        if (contact_email) return contact_email.toLowerCase().includes(input.toLowerCase());
    }

    componentDidMount() {
        this.setState({
                ...this.state,
                selectedContact: null
        })

        const workOrder = this.props.workOrder || this.props.location.workOrder
        let billing = workOrder?.project?.billing_account_po || null
        if (workOrder) {
            this.formRef.current.setFieldsValue({
                ...workOrder,
                service_date: workOrder?.service_date ? moment(workOrder?.service_date) : null,
                start_time: workOrder?.start_time ? moment(workOrder?.start_time, "HH:mm:ss") : null,
                end_time: workOrder?.end_time ? moment(workOrder?.end_time, "HH:mm:ss") : null,
                billing_account_po : billing || workOrder?.billing_account_po || null,
                site_arrival_time: workOrder?.site_arrival_time ?  moment(workOrder?.site_arrival_time, "HH:mm:ss") : null,
                shop_arrival_time: workOrder?.shop_arrival_time ?  moment(workOrder?.shop_arrival_time, "HH:mm:ss") : null,
            })
            const contactDetails = workOrder.site_contact;
            this.setState({
                selectedContact: workOrder && contactDetails ? contactDetails : null
            })
        }
    }

    combineContactLists(siteManagerDetails, billingAccountDetails) {
        const combinedContacts = []
        const siteManagerContact = siteManagerDetails !== undefined && siteManagerDetails[0] !== undefined  ? siteManagerDetails[0].contact : null

        if (siteManagerDetails !== undefined && siteManagerContact !== null && siteManagerContact.length > 0) {
            combinedContacts.push(...siteManagerContact)
        }

        // To avoid duplicated add them to a set and check the set before adding new contacts
        // Needed to filter out duplicates from site manager account and billing account
        const contactIds = new Set();
        combinedContacts.forEach(x => contactIds.add(x.id))

        const billingAccountContacts = billingAccountDetails[0]?.contact;
        if (siteManagerContact !== null && billingAccountContacts !== null &&
            billingAccountContacts !== undefined && billingAccountContacts.length > 0) {
            for (let contact of billingAccountContacts) {
                if (contact.contact.id === siteManagerContact.id || contactIds.has(contact.contact.id)) continue; // Billing Contact can be the same as a Site Contact, so remove the duplicate
                combinedContacts.push(contact.contact) // Contact info is a layer deeper for some reason
                contactIds.add(contact.contact.id)
            }
        }

        return combinedContacts;
    }

    render() {
        let combinedContacts = [];

        if (this.props.workOrder) {
            const siteManagerContact = this.props.workOrder.work_owner_contact, // Site manager?
            billingContacts = this.props.workOrder.work_customer_contact; // Billing Accounts?
            combinedContacts = this.combineContactLists(siteManagerContact, billingContacts) || [];
        }

        return (
            <React.Fragment>
                <div className="row common-form-card-row pb-0 border-top-1">
                    <div className="row set-to-repeat-row">
                        <div className="col-12">
                            <h6 className={"mb-0"}> Site Contact</h6>
                        </div>
                    </div>
                </div>


                <Form
                    ref={this.formRef}
                    onFinish={this.handleSubmit}
                    {...layout}
                    hideRequiredMark={true}
                    className="main-inner-form"
                >
                <div className="row common-form-card-row">
                    <div className="col-12">
                        <div className="row info-gray-div align-items-center mt-0">
                            <h6 className="mb-0">
                                Please select one site contact. The options come from the Billing Account and Site
                                Manager Account listed above.
                            </h6>
                        </div>
                    </div>
                    <div className="col-12 p-0">
                        <div className="row">
                            <div className="col-12">
                                <Form.Item
                                    label={"Site Contact *"}
                                    name="contactSelect"
                                    className="position-relatve"
                                    rules={[{
                                        required: this.state.selectedContact === null,
                                        message: "Please select at least one site contact.",
                                    }]}
                                >
                                    <Select
                                        labelInValue
                                        className="search-and-select-tag"
                                        showSearch={true}
                                        placeholder="Search"
                                        removeIcon={""}
                                        onSelect={this.handleSiteContactChange}
                                        filterOption={this.filterSiteContact}
                                        disabled={this.state.selectedContact !== null}
                                    >
                                        {
                                            combinedContacts.map((contact, idx) => (
                                                <Select.Option key={idx} value={contact.id} contact={contact}>
                                                    <div className="d-flex align-items-center p-2">
                                                        <div className="user-icons-div mr-2">
                                                            <img
                                                                src={Images.contact_file_icon_black}
                                                                alt=""
                                                                className="img-fluid"
                                                            />
                                                        </div>
                                                        <div style={{ width: 'calc(87% - 38px)', minWidth: 300 }}
                                                             className="vc-select-option-data float-left">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <h6 className="d-flex mb-0 align-items-center">
                                                                        {contact.full_name}
                                                                        <small
                                                                            style={{
                                                                                borderLeft: "1px solid #e0e0e0",
                                                                                marginLeft: "5px",
                                                                                paddingLeft: "5px",
                                                                                fontWeight: "500",
                                                                                color: "#bdbdbd",
                                                                            }}
                                                                        >
                                                                            {contact.role}
                                                                        </small>
                                                                    </h6>

                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <h6 className="col-12 vc-select-display-name">
                                                                            <div className="flex-column">
                                                                                <div className="display-data">
                                                                                    {contact.emails && contact.emails.length > 0 && contact.emails[0].email}
                                                                                </div>
                                                                                <div className="display-data">
                                                                                    {contact.phone_numbers && contact.phone_numbers.length > 0 && contact.phone_numbers[0].phone_number}
                                                                                </div>
                                                                            </div>
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Select.Option>
                                            ))
                                        }

                                    </Select>
                                </Form.Item>
                                <Button
                                    className="search-icon bg-transparent border-0 p-0 position-absolute"
                                    style={{ top: 44 }}
                                >
                                    <img
                                        src={Images.search_small_icon}
                                        alt=""
                                        className="img-fluid"
                                    />
                                </Button>
                            </div>
                        </div>

                            {this.state.selectedContact ?
                                (
                                    <div className="row col-12">
                                        <div
                                            style={{
                                                minHeight: "100px",
                                            }}
                                            className="row mx-0 mb-0 align-items-center user-info-div-main opportunity-info-div-main mb-2"
                                        >
                                            <div className="col-12 contact-col-12">
                                                <div className="user-icons-div">
                                                    <img
                                                        src={Images.contact_file_icon_black}
                                                        alt=""
                                                        className="img-fluid"
                                                    />
                                                </div>
                                                <div className="user-info-div mr-3">
                                                    <h6 className="d-flex align-items-center">
                                                        {this.state.selectedContact.first_name}{" "}
                                                        {this.state.selectedContact.last_name}
                                                        <small
                                                            style={{
                                                                borderLeft: "1px solid #e0e0e0",
                                                                marginLeft: "5px",
                                                                paddingLeft: "5px",
                                                                fontWeight: "500",
                                                                color: "#bdbdbd",
                                                            }}
                                                        >
                                                            {this.state.selectedContact.role}
                                                        </small>
                                                    </h6>
                                                    <p className="mb-0">
                                                        {this.state.selectedContact.default_email &&
                                                            `${this.state.selectedContact.default_email?.email} |`}
                                                        &nbsp;
                                                        {this.state.selectedContact.default_phone?.phone_number}
                                                    </p>
                                                </div>
                                                <Dropdown
                                                    overlayClassName="add-remove-dropdown-main"
                                                    placement="bottomCenter"
                                                    overlay={
                                                        <Menu>
                                                            <Menu.Item
                                                                onClick={() =>
                                                                    this.removeContact(
                                                                        this.state.selectedContact
                                                                    )
                                                                }
                                                                key="0"
                                                            >
                                                                <Button className="bg-transparent border-0 shadow-none p-0">
                                                                    Remove
                                                                </Button>
                                                            </Menu.Item>
                                                        </Menu>
                                                    }
                                                    trigger={["click"]}
                                                >
                                                    <Button
                                                        className="bg-transparent position-absolute p-0 border-0 elipsis-btn-card ant-dropdown-link"
                                                        onClick={(e) => e.preventDefault()}
                                                    >
                                                        <img
                                                            src={Images.black_dots_elipsis}
                                                            alt=""
                                                            className="img-fluid"
                                                        />
                                                    </Button>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                )
                                :
                                (
                                    <div className="col-12 px-0">
                                        <div className="row mx-0 no-data-card-row align-items-center justify-content-center">
                                            <div className="col-12 text-center">
                                                <img
                                                    src={Images.Account_no_data_icon}
                                                    alt={"contact-icon"}
                                                    className="img-fluid"
                                                />
                                                <h6 className="mb-0">
                                                    No Site Contact
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            <div className="row">
                                <div className="hr-line" />
                            </div>


                        <div className="col-12">
                            <div className="row info-gray-div align-items-center">
                                <h6 className="mb-0">Please choose service date and time.</h6>
                            </div>
                        </div>

                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <Form.Item
                                        name="service_date"
                                        label={"Service Date *"}
                                       rules={[{
                                           required: true,
                                           message: 'this field is required'
                                       }]}
                                    >
                                        <DatePicker format={"MM/DD/YYYY"}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <Form.Item
                                        name="billing_account_po"
                                        label={"Billing Account PO#/Job#"}
                                    >

                                        <Input placeholder={'Type something'} />
                                    </Form.Item>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <Form.Item
                                        name="start_time"
                                        label={"Requested Site Arrival Time *"}
                                       rules={[{
                                           required: true,
                                           message: 'this field is required'
                                       }]}
                                    >

                                        <TimePicker placeholder={'Select Estimated Start Time'} />
                                    </Form.Item>
                                </div>
                               
                                <div className="col-12 col-sm-6">
                                    <Form.Item
                                        name="end_time"
                                        label={"Estimated End Time"}
                                        //    rules={[{
                                        //        required: true,
                                        //        message: 'this field is required'
                                        //    }]}
                                        className="position-relative"
                                    >
                                        <TimePicker placeholder={'Select Estimated End Time'} />
                                    </Form.Item>
                                </div>

                                <div className="col-12 col-sm-6">
                                    <Form.Item
                                        name="shop_arrival_time"
                                        label={"Requested Shop Arrival Time "}
                                    >

                                        <TimePicker placeholder={'Select Shop Arrival Time'} />
                                    </Form.Item>
                                </div>

                                <div className="hr-line" />
                                <div className="col-12">
                                    <div className="row set-to-repeat-row">
                                        <div className="col-12">
                                            <h6>Set to Repeat</h6>
                                        </div>
                                        <div className="col-12">
                                            <div className="row position-relative">
                                                <div className="col-12 col-sm-6">
                                                    <Form.Item
                                                        name="set_to-repeat"
                                                        label={"Set ot Repeat"}
                                                    //    rules={[{
                                                    //        required: true,
                                                    //        message: 'this field is required'
                                                    //    }]}
                                                    >
                                                        <Select
                                                            // labelInValue
                                                            // disabled={proposal?.opportunity}
                                                            placeholder="Select"
                                                        // notFoundContent={fetching ? <Spin size="small" /> : null}
                                                        // filterOption={false}
                                                        // onSelect={value => this.handleSelectOpp(value)}
                                                        // onFocus={() => this.fetchOpportunities()}
                                                        // onSearch={(e) => this.fetchOpportunities({ search: e })}
                                                        >
                                                            {/*{opportunities.map((d) => (*/}
                                                            <Option key={1} value={1}>
                                                                Does not repeat
                                                            </Option>
                                                            <Option key={2} value={2}>
                                                                Repeat
                                                            </Option>
                                                            {/*))}*/}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                {/*coming-soon-screen*/}
                                                <div className="coming-soon-div coming-soon-div-update">
                                                    Coming Soon
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 validate-div-col text-md-right">
                                    <Form.Item>
                                        <Button
                                            loading={this.state.btnLoader}
                                            htmlType="submit"
                                            className="validate-btn-main"
                                        >
                                            Save and Continue
                                        </Button>
                                    </Form.Item>
                                </div>
                            </div>
                    </div>
                </div>
            </Form>
                {/*<CommonWarningModal
          heading={"Are you sure you want to retrieve information from this opportunity?"}
          subHeadingUOM={
            "If you click Continue, information would be retrieved from the selected opportunity. Once you click Save & Continue on this widget, you cannot change the associated opportunity anymore."
          }
          uomWarning
          retrieveWarning
          okAction={this.handleRetrieveOpp}
          visible={this.state.visibleRetrieveWarning}
          onClose={() => this.handleWarningModal(false)}
        />*/}
            </React.Fragment>
        );
    }
}

// const mapStateToProps = state => {
//   return {...state};
// }
export default withRouter(WorkOrderServiceInfoCreate);
// export default connect(mapStateToProps, {opportunityDetailAction}) (withRouter(GeneralInformation));
